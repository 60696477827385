import React, { FC, memo, useEffect } from 'react';

interface IProps {
    audio: string;
}

const Sound: FC<IProps> = ({ audio }: IProps) => {
    useEffect(() => {
        if (audio) {
            const audioInstance = new Audio(audio);
            const playPromise = audioInstance.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(() => {})
                    .catch(error => {
                        window?.console.info(error);
                    });
            }
        }
    }, [audio]);

    return <></>;
};

export default memo(Sound);
